@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

@import 'flatpickr/dist/themes/material_blue.css';
@import 'react-calendar-heatmap/dist/styles.css';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.date-picker {
	width: 640px;
}

.pink-trash-can {
	margin-bottom: 0.25px;
}

.nutrition-information-list span {
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: rgb(82 82 82);
}

.h-screen-without-header {
	height: Calc(100vh - 150px);
}

/* homepage classes */

.gradient {
	background-image: linear-gradient(-225deg, #cbbacc 0%, #2580b3 100%);
}

.Homepage button,
.Homepage .gradient2 {
	background-color: #f39f86;
	background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
}

.Homepage a.button {
	background-color: #f39f86;
	background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
}

.react-calendar-heatmap {
	height: 100%;
	width: 100%;
}

.react-calendar-heatmap .no-data {
	fill: rgb(229 231 235);
}

.react-calendar-heatmap .color-scale-1 {
	fill: rgb(134 239 172);
}

.react-calendar-heatmap .color-scale-2 {
	fill: rgb(74 222 128);
}

.react-calendar-heatmap .color-success {
	fill: rgb(22 101 52);
}


.shadow-dark {
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0.2), 0 1px 2px -1px rgb(0 0 0 / 0.2);
}
